import React from "react"
import { graphql } from "gatsby"
import rehypeReact from "rehype-react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import { Headline } from "../components/Typography"
import ContentBase from "../components/ContentBase"

const Content = styled(ContentBase)`
  padding-top: calc(8% + ${p => p.theme.nav.height}px);
  padding-bottom: 6%;
`

const Header = styled.div`
  text-align: center;
`

const Paragraph = styled.p`
  font-family: ${p => p.theme.fonts.copy};
  margin-left: auto;
  margin-right: auto;
  ${p =>
    p.children.some(
      ({ props }) => props && props.className === "gatsby-resp-image-link"
    )
      ? css`
          width: "100%";
        `
      : css`
          ${p.theme.mq({
            width: ["100%", "100%", "80%", "66%"],
          })}
        `}
`

const HTwo = styled(Headline)`
  margin-left: auto;
  margin-right: auto;
  ${p =>
    p.theme.mq({
      width: ["100%", "100%", "80%", "66%"],
    })}
`

const Title = styled(Headline)`
  ${p =>
    p.theme.mq({
      fontSize: ["24px", "24px", "36px", "48px"],
    })}
`.withComponent("h1")

const List = styled.ul`
  margin-left: auto;
  margin-right: auto;
  ${p =>
    p.theme.mq({
      width: ["100%", "100%", "80%", "66%"],
    })}
`.withComponent("ul")

const Author = styled.p`
  color: ${p => p.theme.colors.primary};
  font-family: ${p => p.theme.fonts.copy};
  letter-spacing: 2.6px;
  text-transform: uppercase;
`

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {
    h2: HTwo,
    ul: List,
    p: Paragraph,
  },
}).Compiler

const blogTemplate = ({ data, pageContext }) => {
  const { slug } = pageContext
  const { allMarkdownRemark } = data

  if (!(allMarkdownRemark && allMarkdownRemark.edges)) {
    return null
  }

  const { node } = allMarkdownRemark.edges[0]
  const { htmlAst, frontmatter } = node
  const { title, author } = frontmatter

  return (
    <Layout slug={slug}>
      <SEO title={title} />
      <Content>
        <Header>
          <Author>{author}</Author>
          <Title>{title}</Title>
        </Header>
        {renderAst(htmlAst)}
      </Content>
    </Layout>
  )
}

export const query = graphql`
  query PostsBySlug($slug: String!) {
    allMarkdownRemark(filter: { fields: { slug: { eq: $slug } } }) {
      edges {
        node {
          id
          html
          htmlAst
          frontmatter {
            author
            date
            route
            summary
            title
            entry
          }
        }
      }
    }
  }
`

export default blogTemplate
